<template>
  <div class="noorplay_footer" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'" id="nxt-footer" v-if="!hideOptions">
    <div class="footer_Container">
      <footer class="primary-footer" id="primary-footer">
        <div class="footer_Section about_Page">
          <div class="about_img">
            <img src="@/assets/icons/cspacemagenta.svg" width="80%" height="80%" alt="">
          </div>
          <div class="about_desc">
            <p>Your ultimate OTT destination where you can discover award-winners, art films, and more on India's first government-owned platform. Enjoy top-quality entertainment curated just for you. Welcome to C-space where cinema meets culture.</p>
          </div>
        </div>
        <div class="footer_Section our_Apps">
          <p class="connectApps">{{ $t('Our Apps') }}</p>
          <p class="connectPara">{{ $t('experience-same') }}</p>
          <ul>
            <li class="app-links">
              <a :href="appConfig.playstore.android" target="_blank">
                <!-- <googleplaystoresvg></googleplaystoresvg> -->
                <!-- <a href="#" > -->
                <img src="@/assets/icons/cspace-googleplaystore.svg" alt="" class="fillBg">
              </a>
            </li>
            <li class="app-links">
              <a :href="appConfig.playstore.apple" target="_blank">
                <!-- <applestoreicon></applestoreicon> -->
                <!-- <a href="#" > -->
                <img src="@/assets/icons/cspace-applestore.svg" alt="" class="fillBg">
              </a>
            </li>
   
          </ul>
        </div>

        <div class="footer_Section our_Support">
          <p class="contentHeads">{{ $t('Support') }}</p>
        
          <p class="contentPara" >{{$t("You can reach us at: +91 9967600385 (9am to 6pm IST) - Monday to Friday")}}</p>
          <p class="contentPara">{{ $t('supportTimeTxt') }}</p>
   
          <div class="makeAcall">
            <div class="callerImage">
              <img src="@/assets/icons/cspace-onlinesupport.svg" alt="callericon">
            </div>
            <div class="callerNumber">
              <p > <a class="mysupport" href="mailto:support@cspace.tv" >support@cspace.tv</a></p>
            </div>
          </div>
          <div class="socialIcons">
            <ul class="icon_list">
              <li tabindex="1" class="icon" v-if="appConfig.providerDetails.social.facebook"
                @mouseover="actSoicalLogin('fb')">
                <a :href="isActivefacebookLink ? appConfig.providerDetails.social.facebook : ''" rel="noopener"
                  aria-label="facebook link" target="_blank">
                  <!-- <a href="#" > -->
                  <img src="@/assets/icons/cspace-facebookpage.svg" alt="facebook_img" class="fitShare" />
                </a>
              </li>
              <li class="icon" tabindex="1" v-if="appConfig.providerDetails.social.instagram"
                @mouseover="actSoicalLogin('insta')">
                <a :href="isActiveInstagramLink ? appConfig.providerDetails.social.instagram : ''" rel="noopener"
                  aria-label="instagram link" target="_blank">
                  <!-- <a href="#" > -->
                  <img src="@/assets/icons/cspace-instapage.svg" class="fitShare" />
                </a>
              </li>
              <li tabindex="1" class="icon" v-if="appConfig.providerDetails.social.twitter"
                @mouseover="actSoicalLogin('twitter')">
                <a :href="isActiveTwitterLink ? appConfig.providerDetails.social.twitter : ''" rel="noopener"
                  aria-label="twitter link" target="_blank">
                  <!-- <a href="#" > -->
                  <img src="@/assets/icons/cspace-xpage.svg" class="fitShare" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <footer class="secondary-footer">
        <div class="footer_Section cpyRgtPage">
          <p>&copy; 2024 Cspace. All rights reserved</p>
        </div>

        <div class="footerMenu">
          <ul>
            <li>
              <router-link tabindex="1" :to="showTermsOfUse()">
                {{ $t("Terms of Use") }}
              </router-link>
            </li>
            <li>
              <router-link tabindex="1" :to="showPrivacyPolicy()">
                {{ $t("Privacy Policy") }}
              </router-link>
            </li>
            <li>
              <router-link tabindex="1" :to="showFaq()">
                {{ $t("Help Center") }}
              </router-link>
            </li>

          </ul>
        </div>
  
      </footer>
    </div>
  </div>
</template>
 


<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapMutations } from "vuex";
import { _providerId, _projectName } from "@/provider-config.js";
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    showDeepHelp: {
      type: Boolean
    },
    showDeepAbout: {
      type: Boolean
    }
  },
  data() {
    return {
      exploreContent: ["All TV channels", "Recent TV Chaneels", "Popular TV Chaneels", "Suggested TV Chaneels", "All Movies", "Popular Movies", "All TV Shows", "Recent TV Shows", "Popular TV Shows"],
      date: new Date(),
      brand: null,
      showAboutPopup: false,
      showFaqPopup: false,
      showContactPopup: false,
      enableTicket: false,
      showAppShare: false,
      net5Logo: require("@/assets/icons/Net5.svg"),
      nammaflixLogo: require("@/assets/icons/nammaflix_logo.png"),
      providerUniqueId: _providerId,
      localDisplayLang: null,
      cpyRhTxt: "",
      isActivePrivacy: false,
      isActiveTerms: false,
      isActivefacebookLink: false,
      isActiveInstagramLink: false,
      isActiveTwitterLink: false,
      isActiveYoutubeLink: false,
      isActiveAboutusLink: false,
      isActiveShowAbout: false,
      isActiveShowFaq: false,
      hideOptions: false,
      selectedLang: 'ENGLISH'
    };
  },
  computed: {
    ...mapGetters(["appConfig", "getRtl"]),
  },
  watch: {
    $route(to) {
      this.routeCpture();

    },
    showDeepHelp(val) {
      if (val) {
        this.showFaqPopup();
      }
    }
  },
  created() {
    this.routeCpture();
    this.brand =
      _projectName === "VLIVE"
        ? "vLive"
        : _projectName === "NET5"
          ? "Prime Originals"
          : _projectName === "NAMMAFLIX"
            ? "Anviton"
            : _projectName === "Noor Play"
              ? "Noorplay"
              : "";

    this.branchDeeplinks();

    eventBus.$on("show-contact-support", this.showContact);
    eventBus.$on("create-ticket-popup", this.showCreateTicket);
    eventBus.$on("show-share-app-popup", this.showAppReferral);

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
  },
  mounted() {
    if (localStorage.getItem("setDisplayLanguageCode")) {
      this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    }
    this.cpyRhTxt = this.$t(window.PROVIDER_SETUP_CONFIG.copyRight_Txt)
    this.isActivePrivacy = window.PROVIDER_SETUP_CONFIG.isPrivacyEnbaled;
    this.isActiveTerms = window.PROVIDER_SETUP_CONFIG.isTermsEnabled;
    this.isActivefacebookLink = window.PROVIDER_SETUP_CONFIG.isFacebookEnabled;
    this.isActiveInstagramLink = window.PROVIDER_SETUP_CONFIG.isInstagramEnabled;
    this.isActiveTwitterLink = window.PROVIDER_SETUP_CONFIG.isTwitterEnabled;
    this.isActiveYoutubeLink = window.PROVIDER_SETUP_CONFIG.isYoutubeEnabled;
    this.isActiveAboutusLink = window.PROVIDER_SETUP_CONFIG.isAboutusEnabled;
    this.isActiveShowAbout = window.PROVIDER_SETUP_CONFIG.isShowAboutEnabled;
    this.isActiveShowFaq = window.PROVIDER_SETUP_CONFIG.isShowFaqEnabled;
  },
  methods: {
    ...mapMutations(["setRtl"]),
    actSoicalLogin(social) {
      let colorCode = '';
      if (social == 'fb') {
        colorCode = '#1877F2'
      } else if (social == 'insta') {
        colorCode = '#D748C0'
      } else if (social == 'twitter') {
        colorCode = '#1E90FF'
      } else if (social == 'tube') {
        colorCode = '#ED3833'
      }
      document.documentElement.style.setProperty('--social-login-hover-color', colorCode);
    },
    showAbout() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (!this.isActiveShowAbout) {
        return;
      }
      return ({ name: 'about-switch', params: { lang: currentLanguage } });;
      // this.$router.push({name: 'about-switch', params: {lang: currentLanguage}});
    },
    showFaq() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (!this.isActiveShowFaq) {
        return "#";
      }
      return ({ name: 'faq-switch', params: { lang: currentLanguage } });
    },
    showTermsOfUse() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (!this.isActiveTerms) {
        return;
      }


      return ({ name: 'termsofuse', params: { lang: currentLanguage } });

    },
    showPrivacyPolicy() {
      let currentLanguage = this.getCurrentLanguageUrlBase();

      if (!this.isActivePrivacy) {
        return;
      }

      return ({ name: 'privacypolicy', params: { lang: currentLanguage } });

    },
    showContact() {
      this.showFaqPopup = false;
      this.showContactPopup = true;
      document.getElementById("body").style.overflowY = "hidden";
    },
    hideContactPopup(state) {
      this.showContactPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAboutPopup(state) {
      this.showAboutPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleFaqPopup(state) {
      this.showFaqPopup = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    showCreateTicket(state) {
      this.showFaqPopup = false;
      this.enableTicket = state;
    },
    showAppReferral(state) {
      this.showAppShare = state;
      this.showAboutPopup = false;
    },
    toggleCreateTicket(state) {
      this.enableTicket = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    toggleAppReferal(state) {
      this.showAppShare = state;
      document.getElementById("body").style.overflowY = "auto";
    },
    branchDeeplinks() {
      if (this.showDeepHelp) {
        this.showFaq();
      } else if (this.showDeepAbout) {
        this.showAbout();
      }
    },
    routeCpture() {
      let route = this.$router.currentRoute;
      if (
        route.name === "partner-switching" ||
        route.name == "telcoPaymentHandler" ||
        route.name == "addProfile" ||
        route.name == "switchProfile"
      ) {
        this.hideOptions = true;
      } else {
        this.hideOptions = false;
      }
    },
  },
  // components: {
  //   About: () =>
  //     import(/* webpackChunkName: "about" */ "@/components/Popups/AboutUs.vue"),
  //   Faq: () =>
  //     import(
  //       /* webpackChunkName: "Faq" */ "@/components/Popups/Help/FAQ/Faq.vue"
  //     ),
  //   Contact: () =>
  //     import(
  //       /* webpackChunkName: "contact" */ "@/components/Popups/Help/ContactSupport/ContactSupport.vue"
  //     ),
  //   createTicket: () =>
  //     import(
  //       /* webpackChunkName: "createTicket" */ "@/components/Popups/ProfilePopups/createTicket.vue"
  //     ),
  //   AppReferal: () =>
  //     import(
  //       /* webpackChunkName: "appReferal" */ "@/components/Popups/AppReferal.vue"
  //     ),
  //   googleplaystoresvg: () =>
  //     import(
  //      /* webpackChunkName: "appReferal" */ "../SvgImages/Googleplaystroresvg.vue"
  //     ),
  //   applestoreicon: () =>
  //     import(
  //       /* webpackChunkName: "appReferal" */ "../SvgImages/AppStoreSvg.vue"
  //     ),
  //   firetvicon: () =>
  //     import(
  //        /* webpackChunkName: "appReferal" */ "../SvgImages/Firetvsvg.vue"
  //     ),
  //   andriodtviconsvg: () =>
  //     import(
  //        /* webpackChunkName: "appReferal" */ "../SvgImages/AndriodTvicon.vue"
  //     ),

  mixins: [Utility],

};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./noorplay_Footer.scss";

</style>






